import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import store from './redux/store/store';
const theme = createTheme({
  palette: {
    primary: {
      main: '#F8A05F'
    }
  }
});
ReactDOM.render(
  <Provider store={store}>
    <React.Suspense fallback={<div>Loading... </div>}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>,
    </React.Suspense>
  </Provider>,
  document.getElementById("root")
);