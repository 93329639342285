import { Types } from "../constants/user-types";

const initialState = {
  userData: {},
  searchData: {},
  sessionData: [],
  usersData: [],
  profileUpdate: {},
  statuscode: 0,
  subjects: [],
  names: [],
  exportedSummary: {},
  exportedStudent: {},
  deleteGroupMessage: "",
  addUserMessage: "",
  studentCount:0,
  studentsData: [],
  csvData: [],
  dashboardData:{},
  newStudentsData:{},
  groupData:{},
  updateGroupMessage:'',
  sessionCreateMsg: '',
  studentPromoteMsg: '',
  computeAverage: ''
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case Types.PROFILE_FETCHED_SUCCESSFULLY:
      return {
        ...state,
        userData: action.payload,
        statuscode: action.statuscode,
      };
    case Types.PROFILE_SEARCHED_SUCCESSFULLY:
      return {
        ...state,
        searchData: {
          data: action.payload?.data?.summaries,
          csvData:action.payload?.data?.csvSummary
        },
        studentCount: action.payload?.data?.students,
        studentsData: action.payload?.data?.studentsInfo,
        statuscode: action.statuscode,
      };
    case Types.SESSION_CREATED_SUCCESSFULLY:
      return{
        ...state,
        statuscode: action.statusCode,
        sessionCreateMsg: action.payload.data
      }
    
    case Types.STUDENT_PROMOTED_SUCCESSFULLY:
      return {
        ...state,
        statusCode: action.statusCode,
        studentPromoteMsg : action.payload.data
      }

    case Types.SESSION_SEARCHED_SUCCESSFULLY:
      return {
        ...state,
        sessionData: action.payload,
        statuscode: action.statuscode,
      };
      case Types.USERS_FETCHED_SUCCESSFULLY:
      return {
        ...state,
        usersData: action.payload,
        statuscode: action.statuscode,
      };
    case Types.PROFILE_UPDATED_SUCCESSFULLY:
      return {
        ...state,
        profileUpdate: action.payload,
        statuscode: action.statuscode,
      };
    case Types.STUDENTS_FETCHED_SUCCESSFULLY:
      return {
        ...state,
        studentList: action.payload?.data?.summaries,
        studentCount: action.payload?.data?.students,
        csvData:action.payload?.data?.csvSummary,
        statuscode: action.statuscode,
        studentsData: action.payload?.data?.studentsInfo
      };
    case Types.GROUP_SEARCHED_SUCCESSFULLY:
      return {
        ...state,
        subjects: action.payload.allGroups,
        names: action.payload.allNames,
        statuscode: action.statuscode,
      };
    case Types.EXPORT_SUMMARY:
      return { ...state, exportedSummary: action.payload };
    case Types.EXPORT_STUDENT:
        return { ...state, exportedStudent: action.payload };
    case Types.COMPUTE_AVERAGE:
      return {...state, computeAverage: action.payload};
    case Types.DELETE_GROUP:
      return { ...state, deleteGroupMessage: action.payload.message, statuscode: action.statuscode };
    case Types.ERROR_OCCURED:
      return { ...state, studentList: [], statuscode: action.statuscode };
    case Types.GROUP_DATA_FETCHED_SUCCESSFULLY:
      return { ...state, groupData: action.payload.data, statuscode: action.statuscode };
    case Types.GROUP_DATA_UPDATED_SUCCESSFULLY:
      return { ...state, updateGroupMessage: action.payload.data, statuscode: action.statuscode };
    case Types.RESET:
      return {
        ...state,
        userData: {},
        searchData: {},
        profileUpdate: {},
        statuscode: 0,
        subjects: [],
        names: [],
        exportedSummary: {},
        exportedStudent: {},
        deleteGroupMessage: "",
      };
      case Types.DASHBOARD_DATA_FETCHED_SUCCESSFULLY:
      return {
        ...state,
        dashboardData:action.payload.data,
        statuscode: action.statusCode,
      };
      case Types.NEW_STUDENTS_DATA_FETCHED_SUCCESSFULLY:
      return {
        ...state,
        newStudentsData: action.payload.data,
        statuscode:action.statuscode
      }
      case Types.ADD_USER:
      return {
        ...state,
        addUserMessage: action.payload.message,
        statusCodeAddUser:action.statuscode
      }
      case Types.DELETE_USER:
      return {
        ...state,
        statusCodeDeleteUser:action.statuscode
      }
      
    default:
      return state;
  }
}
