export const Types ={
    STUDENTS_FETCHED_SUCCESSFULLY:"STUDENTS_FETCHED_SUCCESSFULLY",
    STUDENTS_UPDATED_SUCCESSFULLY:"STUDENTS_UPDATED_SUCCESSFULLY",
    STUDENTS_UPDATED_BASIC_DETAILS_SUCCESSFULLY: "STUDENTS_UPDATED_BASIC_DETAILS_SUCCESSFULLY",
    RESET:"RESET",
    STUDENT_INFORMATION_FETCHED_SUCCESSFULLY:"STUDENT_INFORMATION_FETCHED_SUCCESSFULLY",
    STUDENT_INFORMATION_FETCHED_SUCCESSFULLY_OTHER:"STUDENT_INFORMATION_FETCHED_SUCCESSFULLY_OTHER",
    DELETE_DOCUMENT_SUCCESSFULLY:"DELETE_DOCUMENT_SUCCESSFULLY",
    UPDATE_STUDENT_HISTORY:"UPDATE_STUDENT_HISTORY"
}
